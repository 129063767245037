import React, {useState, useRef } from "react";
import { Button, Form, Input } from "antd";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import Spin from "../components/Loader";
import { emailAction } from "../actions";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Contact() {
  const formRef = useRef();
  const dispatch = useDispatch();
  const [loading,setLoading] = useState(false)
  const [query, setQuery] = useState({
    subject: '',
    to: '',
    message_body: ''
  });

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setQuery((prevState) => ({
        ...prevState,
        [name]: value
    }));
    };

    const onFinish = async () => {
      setLoading(true) 

      const formdata = {
        subject: query.subject,
        to: query.to,
        message_body: query.message_body
      }
      console.log(formdata)

       const response = await dispatch(emailAction.sendEmailDetails(formdata));
      console.log(response);
      if(response.data.success === true) {
        setLoading(false) 
        formRef.current.setFieldsValue({
          subject: '',
          to: '',
          message_body: ''
      });
      toast.success('Action Successful',{
        autoClose: 2000,
        hideProgressBar: true})
    }
    else {
      toast.error(response?.data?.message,{
        autoClose: 2000,
        hideProgressBar: true })
        setLoading(false) 
      }
  }

  return (
    <div>
      <Header />
      <Navbar />
      {/*  */}
      <div className="image-content about-image">
        <img src="./img/contact.jpg" alt="" />
        <div className="about-content container">
          <h3>Contact Us</h3>
          <p>
            Our customer support provide the best service in the industry.
            We're passionate about our products as well as our customers
            and it shows in the level of service that we provide.
          </p>
        </div>
      </div>

      {/*  */}
      <div className="contact-block1 container add-gap">
        <div className="contact-content2 con1">
          <h2>Get in touch with us</h2>
          <p>
          Satisfied customers are the most precious asset for any business. They are the main drive for the supply chains in each of the three phases: manufacturing, marketing and logistics. For this reason, it is a priority for us as business owner to clearly understand customer needs, preferences and demands, and then work relentlessly to meet them.
          </p>
          <p>
          As successful business leaders, we acknowledge the needs and requirements of our existing and potential customers. Whether the business is small, middle-sized or large, strategies rely on effective logistics.
          </p>
          <div className="contact-button-div">
            <Link to="/contact">
              <Button className="landing-page-cta-btn add-space">Email us</Button>
            </Link>
          </div>
        </div>
        <div className="contact-content">
          <img
            width={500}
            className="img"
            src="./img/contact-image.jpg"
            alt=""
          />
        </div>
      </div>

      {/*  */}
      <div className="container send-a-message">
        <div className="contact-content">
          <img className="img" src="./img/send-a-message.jpg" alt="" />
        </div>
        <div className="contact-content2 con2">
          <h2>Send us a Messages</h2>
          <p>Send us a message or email us if you have any enquires</p>
          <Form
            className="contact-form"
            onFinish={onFinish}
            ref={formRef}
            // ref={formRef}
          >
            <Form.Item
              name="subject"
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                },
              ]}
            >
              <Input  value={query.subject} name='subject' onChange={(e)=>handleInput(e)} className="input" placeholder="Full Name" />
            </Form.Item>

            <Form.Item
              name="to"
              rules={[
                {
                  required: true,
                  message: "Please input your email address!",
                },
              ]}
            >
              <Input value={query.to} name='to' onChange={(e)=>handleInput(e)} className="input" placeholder="Email Address" />
            </Form.Item>

            <Form.Item 
            name="message_body"
            rules={[
              {
                required: true,
                message: "Please input a message",
              },
            ]}>
              <Input.TextArea
                rows={4}
                
                value={query.message_body} name='message_body' onChange={(e)=>handleInput(e)}
                className="d-message"
                placeholder="Message"
              />
            </Form.Item>

              <Button htmlType="submit"  className="landing-page-cta-btn">
                Submit {loading &&  <Spin />}
              </Button>
            
          </Form>
        </div>
      </div>

      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.479884748594!2d3.515710598286983!3d6.587115231965936!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bee135578ca87%3A0x283d5e70be7e1f59!2s2%20Yewa%20Rd%2C%20Ikorodu%20104102%2C%20Ikorodu!5e0!3m2!1sen!2sng!4v1637329478170!5m2!1sen!2sng"
          width="100%"
          height="450"
          style={{ border: "0px" }}
          // style="border:0;"
          // allowfullscreen=""
          loading="lazy"
          title="myFrame"
        ></iframe>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
