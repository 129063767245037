import React, { Component } from "react";
import { Menu, Row, Col, Button } from "antd";
import { Image } from "antd";
import { Link } from "react-router-dom";

class Navbar extends Component {
  state = {
    current: "mail",
  };

  handleClick = (e) => {
    console.log("click ", e);
    this.setState({ current: e.key });
  };

  render() {
    const { current } = this.state;
    return (
      <div className="nav-content container">
        <Row className="desktop-view" type="flex">
          <Col span={10} className="nav-logo">
            <Link to="/">
            <img alt="" width={130} className="" src="./img/primhex-logo.png" />
            </Link>
          </Col>
          <Col span={14} className="nav-menu">
            <Menu
              className="menu-content"
              onClick={this.handleClick}
              selectedKeys={[current]}
              mode="horizontal"
            >
              <Menu.Item key="home">
                <Link to="/">Home</Link>
              </Menu.Item>
              <Menu.Item key="about" title="About">
                <Link to="/about">About us</Link>
              </Menu.Item>
              <Menu.Item key="service" title="Service">
                <Link to="/service">Our Services</Link>
              </Menu.Item>
              <Menu.Item key="contact" title="Contact">
                <Link to="/contact">Contact</Link>
              </Menu.Item>
              <Menu.Item key="book-a-demo" title="Book-a-Demo"> 
              <Link to="/book-a-demo">
                <Button className="register">Book a Demo</Button>
                </Link>
              </Menu.Item>
            </Menu>
          </Col>
        </Row>

        {/* Media query */}
        <div className="media-nav-wrapper">
          <div className="nav-logo">
            <Image width={130} className="" src="./img/primhex-logo.png" />
          </div>
          <Menu
            className="media-menu"
            onClick={this.handleClick}
            selectedKeys={[current]}
            mode="horizontal"
          >
            <Menu.Item key="home" title="Home">
              <Link to="/">Home</Link>
            </Menu.Item>
            <Menu.Item key="about" title="About">
              <Link to="/about">About us</Link>
            </Menu.Item>
            <Menu.Item key="service" title="Service">
              <Link to="/service">Our Services</Link>
            </Menu.Item>
            <Menu.Item key="contact" title="Contact">
              <Link to="/contact">Contact</Link>
            </Menu.Item>
            <Menu.Item key="book-a-demo" title="Book-a-Demo">
            <Link to="/book-a-demo">
                <Button className="register">Book a Demo</Button>
                </Link>
            </Menu.Item>
          </Menu>
        </div>
      </div>
    );
  }
}

export default Navbar;