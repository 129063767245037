import {sendEmailConstants} from "../constant"

const initState = {}
export const sendEmail =  (state = initState, action ) => {
    switch(action.type) {
        case sendEmailConstants.SENDEMAIL_REQUEST:
            return {
                loading: true
            }
        case sendEmailConstants.SENDEMAIL_SUCCESS:
            return {
                sendEmail: action.payload
            }
        case sendEmailConstants.SENDEMAIL_ERROR:
            return {
                error: action.error
            }
            default:
                return state
        }
}