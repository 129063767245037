import React from "react";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Button } from "antd";
import { Link } from "react-router-dom";

function Services() {
  return (
    <div>
      <Header />
      <Navbar />
      {/*  */}
      <div className="image-content about-image">
        <img src="./img/about.jpg" alt="" />
        <div className="about-content container">
          <h3>Our Services</h3>
          <p>
            Coordinating the movement of supplies and materials is now a
            globalized process. Today, the business sector uses logistics as a
            term to describe the efficient flow and storage of goods
          </p>
        </div>
      </div>
      {/* What we do */}
      <div className="content-wrapper container">
        <div className="content-text pt-1">
          <h2>Bike Rentals</h2> 
          <p>
          You can rent 5 fully registered bikes for the price of purchasing one. We offer fully registered dispatch bikes at competitive pricing! Who says you have to start small or grow slowly.
          </p>
          <Link to="/book-a-demo">
            <Button className="landing-page-cta-btn">Register Now</Button>
          </Link>
        </div>
        <div className="content-video">
          <img src="./img/bike-rentals.jpg" alt="" />
        </div>
      </div>

      <div className="content-wrapper-2 container">
      <div className="content-video">
          <img src="./img/office-space.jpg" alt="" />
        </div>
        <div className="content-text pt-1">
          <h2>Shared Spaces</h2> 
          <p>
          Searching for a serene workspace? Look no further! Pitch your tent at our newly renovated office space, complete with work enabled furniture and a well secured parking lot. The shared spaces open up a world of connections and endless possibilities without having to break the bank.
          </p>
          <Link to="/book-a-demo">
            <Button className="landing-page-cta-btn">Register Now</Button>
          </Link>
        </div>
        
      </div>

      <div className="content-wrapper container">
        <div className="content-text pt-1">
          <h2>Web and Mobile Apps</h2> 
          <p>
          Bypass the developmental cost, time and technicalities and enjoy an all round support solution. With experienced hands on deck, we offer rich and user friendly functionalities. Subscribe to our web and mobile applications today!
          </p>
          <Link to="/book-a-demo">
            <Button className="landing-page-cta-btn">Register Now</Button>
          </Link>
        </div>
        <div className="content-video">
          <img src="./img/webdevelopment.jpg" alt="" />
        </div>
      </div>

      <div className="content-wrapper-2 container">
      <div className="content-video">
          <img src="./img/consulting.jpg" alt="" />
        </div>
        <div className="content-text pt-1">
          <h2>Consulting</h2> 
          <p>
          Staying ahead is our watchword!
A team of initiatives that sets you up with industry approved resources, empowers you with proven winning strategies and insights, best practices that gives you a competitive advantage, to jumpstart your budding business.
          </p>
          <Link to="/book-a-demo">
            <Button className="landing-page-cta-btn">Register Now</Button>
          </Link>
        </div>
        
      </div>

     

      {/* <Home /> */}
      <section>
        <div className="primhex-body container">
          <h2 className="main-caption animate__animated animate__fadeInRight">
            Our Products
          </h2>
          <p className="main-text animate__animated animate__fadeInRight">
          Specially designed for users to seamlessly create/schedule orders, make payments and keep track of packages. 
          </p>
          <div className="about-primhex">
            <div className="landing-content animate__animated animate__fadeInRight">
              <img src="./img/icons/user-interface.png" alt="" />
              <h2>User Mobile App</h2>
              <p>
              We ensure billable services that are directly associated with a product
              are delivered. 
              </p>
            </div>
            <div className="landing-content animate__animated animate__fadeInDown">
              <img src="./img/icons/smartphone.png" alt="" />
              <h2>Pilot Mobile App</h2>
              <p>
              Automated to inform riders of available hotspots, gives an estimated time of reach complete.
              </p>
            </div>
            <div className="landing-content animate__animated animate__fadeInRight">
              <img src="./img/icons/layout.png" alt="" />
              <h2>Merchant Dashboard</h2>
              <p>
              This elaborate description gives a rundown of your riders and your business's financials.
              </p>
            </div>
          </div>
          <div className="learn-more">
          <Link to="/products">
            <Button className="landing-page-cta-btn">Learn More</Button>
          </Link>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Services;
