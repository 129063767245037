import React from "react";
import { Carousel, Button } from "antd";
import { Link } from "react-router-dom";
import "animate.css";

const CarouselScreen = () => {
  return (
    <>
      <Carousel autoplay>
        <div>
          <div className="about-image carousel1-image">
            <img src="./img/carousel/carousel1.jpg" alt="" />
            <div className="about-content container animate__animated animate__fadeInLeft">
              <h3>
                The first and leading logistics support platform in Nigeria.
              </h3>
              <p>
                Equipment, Infrastructure, Technology and Insight to make your
                logistics business hassle-free.
              </p>
              <Link to="/service">
                <Button className="landing-page-cta-btn">Learn More</Button>
              </Link>
              {/*  */}
            </div>
          </div>
        </div>
        <div>
          <div className="about-image">
            <img src="./img/carousel4.jpg" alt="" />
            <div className="about-content container">
              <h3>Your No. 1 Logistics Backbone Initiative</h3>
              <p>
                We build from the ground up, providing the fundamental blocks
                and tools needed to run your logistics business efficiently.
              </p>
              <Link to="/product">
                <Button className="landing-page-cta-btn">Learn More</Button>
              </Link>
            </div>
          </div>
        </div>
        {/* <div>
          <div className="about-image">
            <img src="./img/carousel3.jpg" alt="" />
            <div className="about-content container">
              <h3>
                The first and leading logistics support platform in Nigeria.
              </h3>
              <p>
                Equipment, Infrastructure, Technology and Insight to make your
                logistics business hassle-free.
              </p>
              <Link to="/">
                <Button className="landing-page-cta">
                  Learn More
                  <DoubleRightOutlined />
                </Button>
              </Link>
            </div>
          </div>
        </div> */}
        {/* <div>
          <div className="about-image">
            <img src="./img/carousel4.jpg" alt="" />
            <div className="about-content container">
              <h3>
                The first and leading logistics support platform in Nigeria.
              </h3>
              <p>
                Equipment, Infrastructure, Technology and Insight to make your
                logistics business hassle-free.
              </p>
              <Link to="/">
                <Button className="landing-page-cta">
                  Learn More
                  <DoubleRightOutlined />
                </Button>
              </Link>
            </div>
          </div>
        </div> */}
      </Carousel>
    </>
  );
};

export default CarouselScreen;
