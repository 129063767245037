import './App.css';
import './Responsive.css';
// import "./coming-soon.css";
import { BrowserRouter as Router , Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Services from './pages/Services';
// import Login from './pages/Login';
// import Register from './pages/Register';
import BookDemo from './pages/book-a-demo';
import TermsCondition from './pages/Terms&condition';
import Product from './pages/Product';
import PrivacyPolicy from './pages/PrivacyPolicy';
import { ToastContainer } from 'react-toastify';


function App() {
  return (
    <div className="">
      <Router>
      <ToastContainer />
        {/* <Route path="/"  component={ComingSoonScreen} /> */}
        <Route path="/" exact component={Home} />
        <Route path="/about"  component={About} />
        <Route path="/contact"  component={Contact} />
        <Route path="/service"  component={Services} />
        {/* <Route path="/login"  component={Login} /> */}
        {/* <Route path="/register"  component={Register} /> */}
        <Route path="/terms-condition"  component={TermsCondition} />
        <Route path="/products"  component={Product} />
        <Route path="/privacy-policy"  component={PrivacyPolicy} />
        <Route path="/book-a-demo"  component={BookDemo} />
      </Router>
    </div>
  );
}

export default App;
