import React from 'react'
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function TermsCondition() {
    return (
        <div>
            <Header />
      <Navbar />
      <div className='privacyPolicy container'>
          <h2>Terms &amp; Condition of Use</h2>
          <p>
            Supporting your enterprise with our plethora of solutions ranging
            from the visible option of a physical office space to the technical
            aspect which includes but not limited to onboarding of dispatch
            riders.
          </p>
          <p>
            As your No. 1 logistics support system, we help structure and map
            out your existing plan and identify ways to improve.
          </p>
          <p>
          With professionally organized logistics, businesses are able to answer short-time requirements. By choosing an experienced team of professionals, business entrepreneurs can ensure quick and safe shipping, warehousing and delivery of their products to customers.
          </p>
        </div>
      <Footer />
        </div>
    )
}

export default TermsCondition
