import React, {useState, useRef} from "react";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Button, Form, Input } from "antd";
// import { Link } from "react-router-dom";
import Spin from "../components/Loader";
import { emailAction } from "../actions";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function BookDemo() {
    const formRef = useRef();
    const dispatch = useDispatch();
  const [loading,setLoading] = useState(false)
  const [query, setQuery] = useState({
    subject: '',
    to: '',
    message_body: ''
  });

//   console.log(formRef);


  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setQuery((prevState) => ({
        ...prevState,
        [name]: value
    }));
    };

    const onFinish = async () => {
      setLoading(true) 

      const formdata = {
        subject: query.subject,
        to: query.to,
        message_body: query.message_body
      }
      console.log(formdata)

       const response = await dispatch(emailAction.sendEmailDetails(formdata));
      console.log(response);
      if(response.data.success === true) {
        setLoading(false) 
        formRef.current.setFieldsValue({
            subject: '',
            to: '',
            message_body: ''
        });
      toast.success('Action Successful',{
        autoClose: 2000,
        hideProgressBar: true})
    }
    else {
      toast.error(response?.data?.message,{
        autoClose: 2000,
        hideProgressBar: true })
        setLoading(false) 
      }
  }
    return (
        <div>
        <Header />
        <Navbar />
            {/*  */}
            <div className='privacyPolicy container'>
                <div className="contact-content2">
                    <div className="book-a-demo">
                        <h2>Book A Demo</h2>
                        <p>Send us a message or email us if you have any enquires</p>
                        <Form
                            className="contact-form"
                            onFinish={onFinish}
                            ref={formRef}
                        >
                            <Form.Item
                            name="subject"
                            rules={[
                                {
                                required: true,
                                message: "Please input Subject!",
                                },
                            ]}
                            >
                            <Input value={query.subject} name='subject' onChange={(e)=>handleInput(e)} className="input" placeholder="Full Name" />
                            </Form.Item>

                            <Form.Item
                            name="to"
                            rules={[
                                {
                                required: true,
                                message: "Please input your email address!",
                                },
                            ]}
                            >
                            <Input value={query.to} name='to' onChange={(e)=>handleInput(e)} className="input" placeholder="Email Address" />
                            </Form.Item>

                            <Form.Item 
                            // name={["user", "introduction"]}
                            name="message_body"
                            rules={[
                            {
                                required: true,
                                message: "Please input a message",
                            },
                            ]}>
                            <Input.TextArea
                                rows={4}
                                value={query.message_body} name='message_body' onChange={(e)=>handleInput(e)}
                                className="message"
                                placeholder="Message"
                            />
                            </Form.Item>

                            <Button htmlType="submit"  className="landing-page-cta-btn">
                                Submit {loading &&  <Spin />}
                            </Button>
                            
                        </Form>
                    </div>
                </div>
            </div>
            {/*  */}
      <Footer />
        </div>
    )
}
